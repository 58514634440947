const BUCKET_URL = 'https://storage.googleapis.com/hbt-webapp-static/fonts';

const fontStyles = {
  '@font-face': {
    family: 'Roboto',
    src: `url('${BUCKET_URL}/Roboto-Black.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-BlackItalic.woff2') format('woff2'),
            url('${BUCKET_URL}/Roboto-Bold.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-BoldItalic.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-Italic.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-Light.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-LightItalic.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-Medium.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-MediumItalic.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-Regular.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-Thin.woff2') format('woff2'), 
            url('${BUCKET_URL}/Roboto-ThinItalic.woff2') format('woff2'),
      `,
  },
};

export default fontStyles;
