import React from 'react';
import { Box, makeStyles } from '@hummingbirdtechgroup/wings-ui';

const useStyles = makeStyles(({ spacing }: any) => ({
  root: {
    padding: spacing(2, 1),
    height: `calc(100% - ${spacing(2)})`,
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Box flexDirection="column" className={classes.root}>
      {children}
    </Box>
  );
};
