import React from 'react';
import {
  useUser,
  withAuthData,
  hasTokenExpired,
  LoginRedirect,
} from '@hummingbirdtechgroup/wings-auth';
import { RouteProps, SentryRoute } from '@hummingbirdtechgroup/wings-router';

type Props = {
  children: React.ReactElement;
  userId: string;
} & RouteProps<string, { [x: string]: string | undefined }>;

function PrivateRoute({
  children,
  userId,
  ...rest
}: Props): React.ReactElement {
  const [user, fetchUser] = useUser();

  if (userId && user?.id !== userId) {
    fetchUser(userId);
  }

  if (hasTokenExpired()) {
    return <LoginRedirect />;
  }

  return (
    <SentryRoute
      {...rest}
      render={() => (user ? children : 'Loading user data...')}
    />
  );
}

export default withAuthData(PrivateRoute as any);
