import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { DropDownOption, makeStyles } from '@hummingbirdtechgroup/wings-ui';
import { get } from 'lodash-es';
import { useSearchParams } from '@hummingbirdtechgroup/wings-router';
import { Select } from '@hummingbirdtechgroup/wings-forms';
import useGetUserFarms from 'services/farms/useGetUserFarms';
import { UserFarms_farms } from 'services/farms/types/UserFarms.gql';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 400,
    maxWidth: 640,
  },
}));

type Props = {
  intl: any;
};

export default injectIntl(({ intl }: Props) => {
  const classes = useStyles();

  const [urlQueryState, setQueryParams] = useSearchParams();

  const selectedFarm = urlQueryState?.farm;

  const { data: farmsData } = useGetUserFarms();
  const farms = get(farmsData, 'farms', []) as UserFarms_farms[];
  const farmOptions: DropDownOption[] = useMemo(
    () =>
      farms.map(farm => ({
        value: farm.id.toString(),
        label: farm.name,
      })),
    [farms],
  );

  const [selectedFarmOption, setSelectedFarmOption] =
    useState<DropDownOption>();

  useEffect(() => {
    const farmToAdd = farmOptions.find(
      (farm: DropDownOption) => farm?.value === selectedFarm,
    );
    if (farmToAdd) {
      setSelectedFarmOption(farmToAdd);
    }
  }, [farmOptions, urlQueryState]);

  const handleFarmChange = (event: DropDownOption) => {
    setQueryParams({ farm: event?.value }, { merge: true });
  };

  return (
    <Select
      className={classes.root}
      // @ts-ignore
      value={selectedFarmOption}
      options={farmOptions}
      onChange={(value: any) => handleFarmChange(value)}
      loading={!farmOptions.length}
      disabled={!farmOptions.length}
      placeholder={intl.formatMessage({
        id: `analytics.header.selectFarms`,
        defaultMessage: 'Select a farm',
      })}
    />
  );
});
