import { ApplicationType } from 'types/ApplicationType';

const applicationList: Record<
  string,
  { webappKey: ApplicationType; name: string }
> = {
  'nitrogen-variable-rate-application-from-green-area-index': {
    webappKey: 'nitrogenApplicationFromGAI',
    name: 'Nitrogen (GAI) (Gridded)',
  },
  'variable-seed-rate-application-from-normalised-ndvi': {
    webappKey: 'NDVISeedApplication',
    name: 'Seed (Gridded)',
  },
  'application-nitrogen/analysis-gridded_ndvi': {
    webappKey: 'nitrogenApplication',
    name: 'Nitrogen (NDVI) Contoured',
  },
  'application-desiccation/analysis-gridded_ndvi': {
    webappKey: 'desiccationApplication',
    name: 'Dessication (Contoured)',
  },
  'application-fungicide/analysis-gridded_ndvi': {
    webappKey: 'fungicideApplication',
    name: 'Fungicide (Contoured)',
  },
  'application-linear/analysis-gridded_ndvi': {
    webappKey: 'linearNDVIApplication',
    name: 'Open Variable Rate Plan (Contoured)',
  },
  'application-pgr/analysis-gridded_ndvi': {
    webappKey: 'pgrApplication',
    name: 'PGR (NDVI) (Contoured)',
  },
  'application-pgr/analysis-green_area_index': {
    webappKey: 'pgrFromGaiApplication',
    name: 'PGR (GAI) (Contoured)',
  },
  'application-pre_emergence_herbicide/analysis-gridded_ndvi_max': {
    webappKey: 'preEmHerbicideApplication',
    name: 'Pre-emergence Herbicide (Contoured)',
  },
  'application-nitrogen/analysis-mean_size': {
    webappKey: 'nitrogenMeanSizeApplication',
    name: 'Nitrogen (Mean size) (Contoured)',
  },
};

export default applicationList;
