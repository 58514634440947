import React from 'react';
import { createUseStyles } from 'react-jss';
import { merge } from 'lodash-es';
import { UserProvider, useUser } from '@hummingbirdtechgroup/wings-auth';
import { useIntercomMessenger } from '@hummingbirdtechgroup/wings-intercom';
import { SentryScope } from 'services/sentry';
import { QueryClientProvider } from 'react-query';
import { ApolloProvider } from '@apollo/client';
import {
  ThemeProvider,
  defaultTheme,
  normalizeStyles,
  colorsStyles,
  variablesStyles,
} from '@hummingbirdtechgroup/wings-ui';
import { browserHistory, Router } from '@hummingbirdtechgroup/wings-router';
import {
  GoogleAnalytics,
  useGoogleAnalyticsEvent,
} from '@hummingbirdtechgroup/wings-ga';
import {
  ConfigProvider,
  AppEnvironment,
  envConfigs,
} from '@hummingbirdtechgroup/wings-config';
import { IntlProvider } from 'react-intl';
import AppRouter from './AppRouter';
import queryClient from './queryClient';
import { fontStyles, resetStyles } from './styles';
import client from './gqlClient';

const messages = {};

const useStyles = createUseStyles(() => ({
  '@global': {
    ...merge(colorsStyles, variablesStyles),
    ...fontStyles,
    ...resetStyles,
    ...normalizeStyles,
  },
}));

function App() {
  const [user] = useUser();
  useIntercomMessenger({ user });
  useGoogleAnalyticsEvent();
  const { platformAnalyticsCode } =
    envConfigs[process.env.API_ENV as AppEnvironment];
  useStyles();

  return (
    <>
      <AppRouter />
      {process.env.NODE_ENV === 'production' && (
        <>
          <SentryScope />
          <GoogleAnalytics gaTrackingCode={platformAnalyticsCode} />
        </>
      )}
    </>
  );
}

function AppWithProviders(): React.ReactElement {
  const { gqlGatewayUrl } = envConfigs[process.env.API_ENV as AppEnvironment];

  return (
    <ConfigProvider env={process.env.API_ENV as AppEnvironment}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client(gqlGatewayUrl)}>
          <UserProvider>
            <ThemeProvider theme={defaultTheme}>
              <IntlProvider
                locale="en"
                defaultLocale="en"
                messages={{ ...messages }}
              >
                <Router history={browserHistory as any}>
                  <App />
                </Router>
              </IntlProvider>
            </ThemeProvider>
          </UserProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default AppWithProviders;
