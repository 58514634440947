import {
  useQuery,
  gql,
  QueryResult,
  QueryFunctionOptions,
} from '@apollo/client';
import { ADMIN, useUser } from '@hummingbirdtechgroup/wings-auth';

import { UserFarms, UserFarmsVariables } from './types/UserFarms.gql';

export const GET_USER_FARMS = gql`
  query UserFarms($userId: Int) {
    farms(userId: $userId) {
      id
      name
    }
  }
`;

export default function useGetUserFarms(): QueryResult<
  UserFarms,
  UserFarmsVariables
> {
  const [user] = useUser();

  const options: QueryFunctionOptions = { skip: !user };
  if (user && user.role !== ADMIN) {
    options.variables = { userId: user.id };
  }

  return useQuery<UserFarms, UserFarmsVariables>(GET_USER_FARMS, options);
}
