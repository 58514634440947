import { useQuery, gql, QueryResult } from '@apollo/client';
import {
  FarmFieldList,
  FarmFieldListVariables,
} from './types/FarmFieldList.gql';

export const GET_FARM_FIELD = gql`
  query FarmFieldList($farmId: ID!) {
    farm(id: $farmId) {
      fields {
        name
        id
      }
      name
      id
    }
  }
`;

export default (
  farmId: string,
): QueryResult<FarmFieldList, FarmFieldListVariables> =>
  useQuery<FarmFieldList, FarmFieldListVariables>(GET_FARM_FIELD, {
    variables: {
      farmId,
    },
    skip: !farmId,
  });
