import React from 'react';
import { Box, makeStyles } from '@hummingbirdtechgroup/wings-ui';

const useStyles = makeStyles(({ spacing }: any) => ({
  root: {
    padding: spacing(0, 3, 1, 3),
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Box justifyContent="center" alignItems="center" className={classes.root}>
      {children}
    </Box>
  );
};
