import React, { useLayoutEffect } from 'react';
import { useGoogleAnalyticsEvent } from '@hummingbirdtechgroup/wings-ga';

function TrackVisitEvent({
  name,
  children,
}: {
  name: string;
  children: React.ReactElement;
}): React.ReactElement {
  const gaEvent = useGoogleAnalyticsEvent();

  useLayoutEffect(
    () => gaEvent('Component', 'Visit', `Visited ${name} component`),
    [],
  );
  return children;
}

export default TrackVisitEvent;
