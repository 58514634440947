import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

/**
 * Logs any error to Sentry setting relevant details to the scope.
 * If it is a graphql error it will include additional error messages.
 *
 * Console logs are included to improve debugging during development.
 *
 * The requestId is always passed to the server in headers for matching of actual
 * queries in the server logs. In future we could log server errors to sentry too and match them
 * with this id.
 */
export const sentryErrorLogLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    const requestId = operation.getContext().headers['X-Request-ID'];
    const hasGqlErrors = Boolean(graphQLErrors);

    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `%c [GraphQL error]: On operation ${operation.operationName}: Message: ${message}`,
          'color: orange',
          { locations, path },
        ),
      );
    }

    if (networkError) {
      console.log(
        `%c [Network error]: On operation ${operation.operationName}: ${networkError}`,
        'color: red',
      );
      Sentry.withScope(scope => {
        scope.setTransactionName(operation.operationName);
        scope.setContext('X-Request-ID', { requestId });
        scope.setTag('X-Request-ID', `${requestId}`);
        scope.setTag(
          'gql error type',
          hasGqlErrors ? '[Graphql Error]' : '[Network Error]',
        );
        scope.addBreadcrumb({
          category: 'gql query',
          level: Sentry.Severity.Info,
          data: operation.query.loc?.source,
        });
        scope.addBreadcrumb({
          category: 'gql query variables',
          level: Sentry.Severity.Info,
          data: operation.variables,
        });
        if (hasGqlErrors) {
          graphQLErrors?.forEach(error => {
            Sentry.addBreadcrumb({
              category: 'graphqlErrors',
              level: Sentry.Severity.Info,
              data: error,
            });
          });
        }
        Sentry.captureException(networkError);
      });
    }
  },
);
