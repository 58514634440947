// import * as Sentry from '@sentry/react';

function SentryScope(): null {
  // const [email] = useContext(EmailContext);
  // const [language] = useContext(LanguageContext);

  // useEffect(() => {
  //   const company = getCompanyFromEmail(email);
  //   Sentry.configureScope(scope => {
  //     scope.setUser({ email });
  //     scope.setUser({ email });
  //     scope.setTag('page_locale', language);
  //     scope.setExtra('user_company', company);
  //   });
  // }, [email, language]);

  return null;
}

export default SentryScope;
