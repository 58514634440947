import React from 'react';
import { Box, makeStyles } from '@hummingbirdtechgroup/wings-ui';

const useStyles = makeStyles(({ spacing }: any) => ({
  root: {
    margin: spacing(1, 0),
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Box
      alignItems="stretch"
      justifyContent="space-between"
      className={classes.root}
    >
      {children}
    </Box>
  );
};
