import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { v4 as uuid } from 'uuid';
import { authHandlerLink } from './authHandler';
import { sentryErrorLogLink } from './errorHandler';

const setRequestIdLink = setContext((_, prevContext) => ({
  ...prevContext,
  headers: {
    ...prevContext?.headers,
    // a unique request ID for debugging: this is logged on the server for each request
    'X-Request-ID': uuid(),
  },
}));

const httpLink = (gqlGatewayUrl: string) =>
  new HttpLink({
    uri: `${gqlGatewayUrl}/graphql`,
  });

const cache = new InMemoryCache();

export default (gqlGatewayUrl: string) =>
  new ApolloClient({
    link: ApolloLink.from([
      authHandlerLink,
      setRequestIdLink,
      sentryErrorLogLink,
      httpLink(gqlGatewayUrl),
    ]),
    cache,
    queryDeduplication: true,
    name: 'webapp',
    version: process.env.APP_VERSION,
  });
