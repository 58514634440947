import React from 'react';
import { makeStyles } from '@hummingbirdtechgroup/wings-ui';

const useStyles = makeStyles(({ spacing, palette }: any) => ({
  root: {
    width: '100%',
    marginTop: spacing(2),
    marginBottom: spacing(2),
    border: `1px solid ${palette.grey.main}`,
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <table className={classes.root}>{children}</table>;
};
