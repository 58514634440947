import React from 'react';
import {
  ExternalLinkIcon,
  Link,
  makeStyles,
  ToolTip,
} from '@hummingbirdtechgroup/wings-ui';
import isWithinInterval from 'date-fns/isWithinInterval';
import { FarmGrowingSeasons_growingSeasons } from 'services/farms/types/FarmGrowingSeasons.gql';
import { ApplicationSurvey } from 'types/application';
import { ApplicationType } from 'types/ApplicationType';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

const useStyles = makeStyles(({ spacing, shadows, palette, zIndex }: any) => ({
  labelContainer: {
    position: 'relative',
    textAlign: 'center',
    background: `${palette.grey.offBlack} 0% 0% no-repeat padding-box`,
    padding: spacing(),
    color: 'white',
    border: `1px solid ${palette.grey.light}`,
    borderRadius: '5px',
    boxShadow: shadows[1],
    width: spacing(15.8),
    opacity: 0.9,
    zIndex: zIndex.leafletMax + 2,
    marginLeft: spacing(2),
  },
}));

type Application = {
  id: string;
  product_type_name: string;
  created_at: Date;
  enabled: boolean;
  status: string;
  survey: ApplicationSurvey;
};

type Props = {
  application: Application;
  farmId: string;
  openApplication: ApplicationType;
  survey: ApplicationSurvey;
  seasons: FarmGrowingSeasons_growingSeasons[];
};

function findActiveSeason(
  seasons: FarmGrowingSeasons_growingSeasons[],
  surveyDate: string,
) {
  return seasons.find((season: FarmGrowingSeasons_growingSeasons) =>
    isWithinInterval(new Date(surveyDate), {
      start: new Date(season.startDate),
      end: new Date(season.endDate),
    }),
  );
}

function getSurveyLink({
  application,
  appUrl,
  farmId,
  survey,
  seasons,
  openApplication,
}: Props & { appUrl: string }) {
  const selectedSeason = findActiveSeason(seasons, survey.captured_at);
  let url = `${appUrl}/map/${farmId}/field/${survey.field_id}/application?applicationId=${application.id}&surveyId=${survey.id}`;
  if (selectedSeason && openApplication)
    url += `&seasonId=${selectedSeason.id}&openApplication=${openApplication}`;
  return url;
}

export default ({
  application,
  farmId,
  openApplication,
  survey,
  seasons,
}: Props) => {
  const { appUrl } = useConfig();
  const classes = useStyles();

  return (
    <ToolTip
      position={['right']}
      className={classes.labelContainer}
      content="Show on the map"
    >
      <Link
        href={getSurveyLink({
          appUrl,
          application,
          openApplication,
          farmId,
          survey,
          seasons,
        })}
        target="_blank"
      >
        <ExternalLinkIcon size="mediumLarge" />
      </Link>
    </ToolTip>
  );
};
