import { useQuery, gql, QueryResult } from '@apollo/client';
import {
  FarmGrowingSeasons,
  FarmGrowingSeasonsVariables,
} from './types/FarmGrowingSeasons.gql';

const GET_FARM_SEASONS = gql`
  query FarmGrowingSeasons($growingSeasonsFarmId: Int) {
    growingSeasons(farmId: $growingSeasonsFarmId) {
      endDate
      id
      name
      startDate
    }
  }
`;

export default (
  farmId?: number | null,
): QueryResult<FarmGrowingSeasons, FarmGrowingSeasonsVariables> =>
  useQuery<FarmGrowingSeasons, FarmGrowingSeasonsVariables>(GET_FARM_SEASONS, {
    variables: {
      growingSeasonsFarmId: farmId,
    },
    skip: !farmId,
  });
