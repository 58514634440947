import React from 'react';
import { makeStyles } from '@hummingbirdtechgroup/wings-ui';

const useStyles = makeStyles(() => ({
  root: { display: 'flex' },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <tr className={classes.root}>{children}</tr>;
};
