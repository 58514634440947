import React from 'react';
import { Box, H1, makeStyles } from '@hummingbirdtechgroup/wings-ui';

const useStyles = makeStyles(({ palette, spacing }: any) => ({
  root: {
    padding: spacing(0, 2, 1, 2),
    borderBottom: `0.5px solid ${palette.secondary.main}`,
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <H1>{children}</H1>
    </Box>
  );
};
