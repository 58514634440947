import React from 'react';
import { makeStyles, TH } from '@hummingbirdtechgroup/wings-ui';

type Props = { children?: React.ReactNode; cellWidth?: string };

const useStyles = makeStyles(({ spacing, palette }: any) => ({
  root: {
    lineHeight: '21px',
    padding: spacing(2),
    borderRight: `1px solid ${palette.grey.main}`,
    borderBottom: `1px solid ${palette.grey.main}`,
    textAlign: 'left',
    flexGrow: ({ cellWidth }: Props) => (cellWidth === 'auto' ? 1 : 0),
    flexBasis: ({ cellWidth }: Props) => cellWidth,
    width: ({ cellWidth }: Props) => cellWidth,
  },
}));

export default ({ children, cellWidth = 'auto' }: Props) => {
  const classes = useStyles({ cellWidth });
  return <TH className={classes.root}>{children}</TH>;
};
