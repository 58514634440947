import React from 'react';
import { makeStyles } from '@hummingbirdtechgroup/wings-ui';

const useStyles = makeStyles(() => ({
  root: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <thead className={classes.root}>{children}</thead>;
};
