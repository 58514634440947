import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@hummingbirdtechgroup/wings-ui';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

export default ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const classes = useStyles();
  return (
    <tbody className={classNames(classes.root, className)}>{children}</tbody>
  );
};
